import React from "react";
import Account from "../Account/Account";
import Footer from "../footer/Footer";
import "./fullAccountPage.css";
const Page = () => {
  return (
    <div className="accountFooterContainer">
      <Account />
      <Footer />
    </div>
  );
};

export default Page;

import React, { useState, useEffect } from "react";
import { subCategoriesGropu } from "../Categories/Categories";
import { useNavigate } from "react-router-dom";

import "./style.css";
import logo from "../../AccountPage/Account/selfPauseLogo.jpg";
import Footer from "../../AccountPage/footer/Footer";
import { success } from "../../../assets/AffirmationsChoiceAssets";
import { useStateContext } from "../../../context/context";
import toast from "react-hot-toast";
const StepTwo = () => {
  const {
    subCategories,
    selectedSubCategories,
    selectSubCategoriesStepTwo,
    setSelectedSubCategories,
  } = useStateContext();

  const navigate = useNavigate();

  // useffect
  useEffect(() => {
    // setSelectedSubCategories([]);
  }, []);
  let subcategoriesarray = [...selectedSubCategories];

  return (
    <div className="categoriesComponentContainerStepTwo">
      <div className="firstSectionContainerStepTwo">
        <nav className="navbarStepTwo">
          <img src={logo} alt="" />
        </nav>
        <section className="categoriesSectionStepTwo">
          <div className="titlesStepTwo">
            <h1>Free Affirmation Generator Tool</h1>
            <p>
              What areas of your life could benefit the most from affirmations ?
            </p>
          </div>
          <div className="categoriesStepTwo">
            <div className="secondCategoriesStepTwo">
              {subCategories?.map((subCategory, index) => (
                <React.Fragment key={index}>
                  {subCategory.array.map((subCategoryName) => (
                    <div
                      key={subCategoryName.subName}
                      className="categoryStepTwo"
                      onClick={() => {
                        const found = subcategoriesarray.find(
                          (item) => item === subCategoryName.subName
                        );
                        if (found) {
                          subCategoryName.selected2 =
                            !subCategoryName.selected2;
                          const filteredarray = subcategoriesarray.filter(
                            (item) => item !== subCategoryName.subName
                          );
                          subcategoriesarray = [...filteredarray];
                          console.log("here filter and stop", filteredarray);

                          selectSubCategoriesStepTwo([...subcategoriesarray]);
                          console.log(subcategoriesarray);

                          return;
                        } else {
                          if (subcategoriesarray.length >= 3) {
                            toast.error(
                              `you can't choose more than 3 categories`
                            );
                            return;
                          }
                          subCategoryName.selected2 =
                            !subCategoryName.selected2;

                          subcategoriesarray = [
                            ...subcategoriesarray,
                            subCategoryName.subName,
                          ];
                          selectSubCategoriesStepTwo([...subcategoriesarray]);
                          console.log(subcategoriesarray);
                        }
                      }}
                    >
                      <div className="imgAndTextDivStepTwo">
                        {/* <img src="" alt="" className="categoryLogosStepTwo" /> */}
                        <p>{subCategoryName.subName}</p>
                      </div>
                      {subCategoryName.selected2 && (
                        <img
                          src={success}
                          alt=""
                          className="categoryLogosStepTwo"
                        />
                      )}
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          </div>
          <div className="btnDivStepTwo">
            <button onClick={() => navigate("/stepOne")}>Back </button>
            <button
              onClick={() => {
                if (subcategoriesarray.length < 1) {
                  toast.error("Please select a subcategory");
                  return;
                } else {
                  navigate("/stepThree");
                }
              }}
            >
              NEXT (2/3)
            </button>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default StepTwo;

import React, { useState, useEffect } from "react";
import { Categories } from "../Categories/Categories";
import "./style.css";
import { useNavigate } from "react-router-dom";
import logo from "../../AccountPage/Account/selfPauseLogo.jpg";
import Footer from "../../AccountPage/footer/Footer";
import { success } from "../../../assets/AffirmationsChoiceAssets";
import { useStateContext } from "../../../context/context";
import toast from "react-hot-toast";
const StepOne = () => {
  const [rendered, setRendered] = useState(false);
  const [categoryName, setCategoryName] = useState([]);
  const [categories, setCategories] = useState("");

  const { selectedCategories, selectCategories, subCategoriesGropu } =
    useStateContext();

  const navigate = useNavigate();
  // useffect
  useEffect(() => {
    setCategories(Categories);

    console.log(selectedCategories, "selecttedcategories");
  }, [rendered, selectedCategories]);
  // onchange
  let categoriesarray = [...selectedCategories];

  return (
    <div className="categoriesComponentContainerStepOne">
      <div className="firstSectionContainerStepOne">
        <nav className="navBarStepOne">
          <img src={logo} />
        </nav>
        <section className="categoriesSectionStepOne">
          <div className="titlesStepOne">
            <h1>Free Affirmation Generator Tool</h1>
            <p>
              What areas of your life could benefit the most from affirmations ?
            </p>
          </div>
          <div className="categoriesStepOne">
            <div className="firstCategoriesStepOne">
              {Categories[0]?.firstCategories?.map((category) => (
                <div
                  key={category.name}
                  className="categoryStepOne"
                  onClick={() => {
                    setRendered(!rendered);

                    const found = categoriesarray.find(
                      (item) => item === category.name
                    );
                    if (found) {
                      category.selected = !category.selected;

                      const filteredarray = categoriesarray.filter(
                        (item) => item !== category.name
                      );
                      categoriesarray = [...filteredarray];
                      console.log("here filter and stop", filteredarray);

                      selectCategories([...categoriesarray]);
                      subCategoriesGropu.map((subCategory) => {
                        if (category.name === subCategory.name) {
                          subCategory.selected = false;
                          console.log(subCategory.selected);
                        }
                      });
                      return;
                    } else {
                      if (categoriesarray.length >= 3) {
                        toast.error(`you can't choose more than 3 categories`);
                        return;
                      }
                      category.selected = !category.selected;

                      categoriesarray = [...categoriesarray, category.name];
                      selectCategories([...categoriesarray]);
                      subCategoriesGropu.map((subCategory) => {
                        if (category.name === subCategory.name) {
                          subCategory.selected = true;
                          console.log(subCategory.selected);
                          console.log(subCategory);
                        }
                      });
                    }
                  }}
                >
                  <div className="imgAndTextDivStepOne">
                    <img
                      src={category.imgUrl}
                      alt=""
                      className="categoryLogosStepOne"
                    />
                    <p>{category.name}</p>
                  </div>
                  {category.selected && (
                    <img
                      src={success}
                      alt=""
                      className="categoryLogosStepOne"
                    />
                  )}
                </div>
              ))}
            </div>

            <div className="secondCategoriesStepOne">
              {Categories[1]?.secondCategories?.map((category) => (
                <div
                  key={category.name}
                  className="categoryStepOne"
                  onClick={() => {
                    setRendered(!rendered);
                    const found = categoriesarray.find(
                      (item) => item === category.name
                    );
                    if (found) {
                      const filteredarray = categoriesarray.filter(
                        (item) => item !== category.name
                      );
                      categoriesarray = [...filteredarray];
                      console.log("here filter and stop", filteredarray);
                      selectCategories([...categoriesarray]);
                      subCategoriesGropu.map((subCategory) => {
                        if (category.name === subCategory.name) {
                          subCategory.selected = false;
                          console.log(subCategory.selected);
                        }
                      });
                      return;
                    } else {
                      if (categoriesarray.length >= 3) {
                        toast.error(`you can't choose more than 3 categories`);
                        return;
                      }
                      category.selected = !category.selected;
                      categoriesarray = [...categoriesarray, category.name];
                      selectCategories([...categoriesarray]);
                      subCategoriesGropu.map((subCategory) => {
                        if (category.name === subCategory.name) {
                          subCategory.selected = true;
                          console.log(subCategory.selected);
                          console.log(subCategory);
                        }
                      });
                    }
                  }}
                >
                  <div className="imgAndTextDivStepOne">
                    <img
                      src={category.imgUrl}
                      alt=""
                      className="categoryLogosStepOne"
                    />
                    <p>{category.name}</p>
                  </div>
                  {category.selected && (
                    <img
                      src={success}
                      alt=""
                      className="categoryLogosStepOne"
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={() => {
              if (categoriesarray.length < 1) {
                toast.error("Please select a category");
                return;
              } else {
                navigate("/stepTwo");
              }
            }}
          >
            NEXT (1/3)
          </button>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default StepOne;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import logo from "../../AccountPage/Account/selfPauseLogo.jpg";
import Footer from "../../AccountPage/footer/Footer";
import copyImg from "../../../assets/AffirmationsChoiceAssets/copy.webp";
import { useStateContext } from "../../../context/context";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const StepFour = () => {
  const [affirmations, setAffirmations] = useState();
  const navigate = useNavigate();
  const {
    selectedSubCategories,
    choice,
    selectedAffirmations,
    selectAffirmations,
  } = useStateContext();

  useEffect(() => {
    setAffirmations(selectedAffirmations);
  }, [selectedAffirmations]);

  return (
    <div className="categoriesComponentContainerStepFour">
      <div className="firstSectionContainerStepFour">
        <nav className="navbarStepFour">
          <img src={logo} alt="" />
        </nav>
        <section className="categoriesSectionStepFour">
          <div className="titlesStepFour">
            <h1>Free Affirmation Generator Tool</h1>
            <p>Here are your personalized affirmations </p>
          </div>

          <div className="affirmations">
            {selectedAffirmations.map((aff, index) => (
              <React.Fragment key={index}>
                {" "}
                <p>
                  {`${index + 1}`}. {aff}
                </p>
              </React.Fragment>
            ))}
            <CopyToClipboard
              text={affirmations}
              onCopy={() => toast.success("Copied")}
            >
              <img className="copyImg" src={copyImg} alt="" />
            </CopyToClipboard>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default StepFour;

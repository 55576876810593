import React, { useState, useContext, createContext, useEffect } from "react";
import {
  Categories,
  subCategoriesGropu,
  Affirmations,
} from "../components/AffirmationsChoice/Categories/Categories";

const Context = createContext();
export const StateContext = ({ children }) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedAffirmations, setSelectedAffirmations] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [affirmations, setAffirmations] = useState([]);
  const [choice, setChoice] = useState(5);
  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    let sub = [];

    subCategoriesGropu.map((subCategory) => {
      if (subCategory.selected) {
        sub = [...sub, subCategory];
        setSubCategories([...sub]);
      }
    });

    console.log(sub, "from context", subCategories);
  }, [selectedCategories, isSelected, selectedSubCategories]);
  // select category on step one
  const selectCategories = (categories) => {
    setSelectedCategories(categories);
  };
  // assign subcategories to relevent category on step one
  const selectSubCategories = (subcategories) => {
    setSubCategories(subcategories);
    console.log("subcategories from context", subcategories);
    console.log("subcategories 2 from cotnext", subCategories);
  };
  // step two select categories
  const selectSubCategoriesStepTwo = (selectedsubcategories) => {
    setSelectedSubCategories(selectedsubcategories);
  };

  // Number of affirmations
  const selectNumberOfAffirmations = (e) => {
    setChoice(e.target.value);
    console.log(choice);
  };
  // select affirmations
  const selectAffirmations = (affirmations) => {
    setSelectedAffirmations(affirmations);
  };
  // console.log(subCategories, "subCa", selectedCategories);
  return (
    <Context.Provider
      value={{
        selectCategories,
        setSelectedCategories,
        selectSubCategories,
        selectedCategories,
        subCategories,
        subCategoriesGropu,
        selectedAffirmations,
        isSelected,
        choice,
        selectedSubCategories,
        setIsSelected,
        selectSubCategoriesStepTwo,
        selectNumberOfAffirmations,
        setSelectedSubCategories,
        selectAffirmations,
        setSelectedAffirmations,
        setChoice,
      }}
    >
      {children}
    </Context.Provider>
  );
};
export const useStateContext = () => useContext(Context);

import React from "react";
import { Box, Typography } from "@mui/material";

const IntroCard = ({ image, title, subtitle }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      width={"100%"}
      m={2}
      my={4}
    >
      <Box display={"flex"} flexDirection={"row"}>
        <img src={image} alt="ambient" width="40" height="100%" />

        <Box display={"flex"} flexDirection={"column"} mx={3}>
          <Typography fontFamily={"Comfortaa"} fontWeight={"500"} variant={"p"} fontSize={22}>
            {title}
          </Typography>
          <Typography variant="caption" fontFamily={"Comfortaa"} mt={2} fontSize={14}>{subtitle}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default IntroCard;

import "./App.css";
import Auth from "./components/Authentication/Auth";
import Payment from "./components/Payment/Payment";
import Login from "./components/Authentication/Login";
import Intro from "./components/Intro/Intro";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Toaster } from "react-hot-toast";
import Page from "./components/AccountPage/fullAccountPages/fullAccountPage";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/system";
import StepOne from "./components/AffirmationsChoice/stepOne/StepOne";
import StepTwo from "./components/AffirmationsChoice/stepTwo/stepTwo";
import StepThree from "./components/AffirmationsChoice/stepThree/StepThree";
import StepFour from "./components/AffirmationsChoice/stepFour/StepFour";
import { StateContext } from "./context/context";

function App() {
  const publishableKey =
    "pk_live_51Ji2uRE1Bn4towtFTzb40mh6bMb6ByZVydp0ISp6Lpf6QOiOnbPXcscrLIQ4P7VAfsoovCkhqMKjc3gAC8BqknMs00XnH7P17e"; //PROD
  // const publishableKey = "pk_test_51Ji2uRE1Bn4towtFwo91wBTNlcfnqo9yLz4V8CEx8j5PPMqhZA8N50uogNdHcUK51BbN1jRVNzoHqS8MKrQZaPZA00DgbVnkLn"; // TEST
  const stripePromise = loadStripe(publishableKey);

  return (
    <>
      <Toaster />
      <Router>
        <Elements stripe={stripePromise}>
          <StateContext>
            <Routes>
              <Route exact path="/" element={<Intro />} />
              <Route exact path="/stepOne" element={<StepOne />} />
              <Route exact path="/stepTwo" element={<StepTwo />} />
              <Route exact path="/stepThree" element={<StepThree />} />
              <Route exact path="/stepFour" element={<StepFour />} />
              <Route exact path="/authentication" element={<Auth />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/account" element={<Page />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </StateContext>
        </Elements>
      </Router>
    </>
  );
}

export default App;

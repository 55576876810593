import React, { useEffect, useState } from "react";
import {
  Box,
  ThemeProvider,
  createTheme,
  CircularProgress,
} from "@mui/material";
import Logo from "../../components/logo.png";
import IntroWebView from "./IntroWebView";
import IntroMobileView from "./IntroMobileView";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
export default function Intro() {
  const isMobile = useMediaQuery({ query: `(max-width: 785px)` });
  const [canRender, setCanRender] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (
      window.location.href === "https://affirmations-generator.selfpause.com/"
    ) {
      navigate("/stepOne");
    }
    setTimeout(() => {
      setCanRender(true);
    }, 500);
  }, []);

  if (!canRender) {
    return (
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress style={{ color: "#008AB7" }} />
      </Box>
    );
  }

  return (
    <Box
      flexDirection={"column"}
      justifyContent={"space-around"}
      display={"flex"}
    >
      <Box display="flex" justifyContent="center" mt={5}>
        <img src={Logo} alt="SelfPauseLogo" width="60px" height="60px" />
      </Box>
      <Box display="flex" justifyContent="center" mt={5}>
        {isMobile ? <IntroMobileView /> : <IntroWebView />}
      </Box>
    </Box>
  );
}

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Logo from "../../components/logo.png";
import onboardingMobile from "../../components/onboarding-mobile.png";
import affirmations from "../../components/Affirmations.png";
import ambient from "../../components/Ambient.png";
import recordings from "../../components/Recordings.png";
import { useNavigate } from "react-router-dom";
import IntroCard from "./IntroCard";

export default function IntroMobileView() {
  const navigate = useNavigate();

  return (
    <Box
      mx={"5%"}
      backgroundColor={"#F2FCFC"}
      borderRadius={5}
      flexDirection={"column"}
      display={"flex"}
      mb={5}
    >
      <Box width={"100%"} height={"35%"} overflow={"hidden"}>
        <img
          style={{
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          src={onboardingMobile}
          alt="SelfPauseLogo"
          width="100%"
          height="undefined"
          
        />
      </Box>
      <Box>
        <Typography textAlign={"center"} sx={{fontFamily:"Comfortaa"}} variant="h6" m={5} fontWeight={700} fontSize={24}>
            Unlock your mindset & success with affirmations.
        </Typography>

        <IntroCard
          image={affirmations}
          title={"Guided affirmation sessions by experts"}
          subtitle={
            "Listen to 1,000's of professionally recorded affirmations."
          }
        />

        <IntroCard
          image={recordings}
          title={"Self-recorded personal affirmations"}
          subtitle={
            "Write and self-record unlimited personalized affirmations."
          }
        />

        <IntroCard
          image={ambient}
          title={"Premium ambient sounds and music"}
          subtitle={
            "Get the full library of ambient sounds to layer with your affirmations."
          }
        />

        <Box display={"flex"} justifyContent={"center"} mb={5}>
          <Button
            style={{
              width: "60%",
              backgroundColor: "#008AB7",
              fontFamily:"Comfortaa"
            }}
            onClick={() => navigate("/authentication")}
            type="submit"
            variant="contained"
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
}


import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
  apiKey: 'AIzaSyC5MJSqr-IEevUCfufDnZvOHUIHlqJVFPw',
  authDomain: 'selfpause-10e5a.firebaseapp.com',
  databaseURL: 'https://selfpause-10e5a.firebaseio.com',
  projectId: 'selfpause-10e5a',
  storageBucket: 'selfpause-10e5a.appspot.com',
  messagingSenderId: '1044371968440',
  appId: '1:1044371968440:web:741faceca640ac1b2abf4d',
  measurementId: 'G-0KCGBEPL30',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore();
export const auth = getAuth();
const analytics = getAnalytics(app);

import React from "react";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerDetails">
        <p> &copy; 2022 Selfpause All rights reserved</p>
        <div className="footerLinks">
          <a href="https://selfpause.com/privacy-policy">Privacy Policy</a>
          <a href="https://selfpause.com/terms-conditions">Terms of Service</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React, { useState, useEffect } from "react";
import Footer from "../../AccountPage/footer/Footer";
import logo from "../../AccountPage/Account/selfPauseLogo.jpg";
import "./style.css";
import { Affirmations } from "../Categories/Categories";
import { useStateContext } from "../../../context/context";
import { useNavigate } from "react-router-dom";
const StepThree = () => {
  const [email, setEmail] = useState("");

  const {
    selectNumberOfAffirmations,
    selectedSubCategories,
    choice,
    setChoice,
    selectedAffirmations,
    selectAffirmations,
    setSelectedAffirmations,
  } = useStateContext();
  const navigate = useNavigate();
  useEffect(() => {
    setSelectedAffirmations([]);
    setChoice(5);
  }, []);
  //  new array
  let affirmations = [...selectedAffirmations];
  // function to create affirmations
  const createAffirmations = () => {
    selectedSubCategories.map((subcategory) => {
      console.log(selectedAffirmations);
      Affirmations.map((affirmation) => {
        if (subcategory === affirmation.name) {
          if (selectedSubCategories.length === 1) {
            console.log(choice);
            console.log("type of", typeof choice);

            if (choice === 5) {
              affirmations = [...affirmation.array].slice(0, 5);
            } else if (choice == 10) {
              console.log(choice);

              affirmations = [...affirmation.array].slice(0, 10);
            } else if (choice == 15) {
              affirmations = [...affirmation.array].slice(0, 15);
            }
            selectAffirmations([...affirmations]);
            console.log(affirmations);
          } else if (selectedSubCategories.length === 2) {
            if (choice == 5) {
              console.log("affirmationsbefore", affirmations);
              if (affirmations.length > 0) {
                affirmations.shift();
              }
              affirmations = [
                ...affirmations,
                ...affirmation.array.slice(0, 3),
              ];
            } else if (choice == 10) {
              affirmations = [
                ...affirmations,
                ...affirmation.array.slice(0, 5),
              ];
            } else if (choice == 15) {
              if (affirmations.length > 0) {
                affirmations.shift();
              }
              affirmations = [
                ...affirmations,
                ...affirmation.array.slice(0, 8),
              ];
            }
            selectAffirmations([...affirmations]);
          } else if (selectedSubCategories.length === 3) {
            if (choice == 5) {
              console.log("affirmationsbefore", affirmations);
              if (affirmations.length > 0) {
                affirmations.splice(0, 2);
              }
              affirmations = [
                ...affirmations,
                ...affirmation.array.slice(0, 3),
              ];
            } else if (choice == 10) {
              if (affirmations.length > 0) {
                affirmations.splice(0, 1);
              }
              affirmations = [
                ...affirmations,
                ...affirmation.array.slice(0, 4),
              ];
            } else if (choice == 15) {
              if (affirmations.length > 0) {
                affirmations.splice(0, 3);
              }
              affirmations = [
                ...affirmations,
                ...affirmation.array.slice(0, 7),
              ];
            }
            selectAffirmations([...affirmations]);
          }
        }
      });
      console.log("affirmations", affirmations);
    });
    navigate("/stepFour");
  };
  return (
    <div className="categoriesComponentContainerStepThree">
      <div className="firstSectionContainer">
        <nav className="navBarStepThree">
          <img src={logo} alt="" />
        </nav>
        <section className="categoriesSectionStepThree">
          <div className="titlesStepThree">
            <h1>Free Affirmation Generator Tool</h1>
            <p>How many affirmations do you want</p>
            <div className="select">
              <select onChange={selectNumberOfAffirmations}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
              </select>
            </div>

            <input
              type="email"
              placeholder="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button onClick={createAffirmations}>CREATE AFFIRMATIONS</button>
        </section>
      </div>
      <Footer />
    </div>
  );
};
export default StepThree;

import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "../../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  Box,
  ThemeProvider,
  createTheme,
  CircularProgress,
} from "@mui/material";
import "./Account.css";
import axios from "axios";
import selfPauseLogo from "./selfPauseLogo.jpg";
import accountLogo from "./accountLogo.jpg";
import closeLogo from "./close.png";
const Account = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [endDate, setEndDate] = useState("");
  const [receiptId, setreceiptId] = useState("");
  const [canRender, setCanRender] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [isExpired, setIsExpired] = useState(true);

  const isMounted = useRef(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.location.href === "https://affirmations-generator.selfpause.com/"
    ) {
      navigate("/stepOne");
    }
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setEmail(auth?.currentUser?.email);
          setName(auth?.currentUser?.displayName);
        } else {
          navigate("/authentication");
        }
      });
    }

    // getting stripe  accountid
    const getdooc = async () => {
      const docreferecene = doc(db, "users", auth?.currentUser?.uid);
      const data = await getDoc(docreferecene);
      if (data) {
        if (!data.data()?.receipt) {
          // navigate("/payment");
          setIsExpired(true);
          return;
        }
        setIsExpired(false);

        setreceiptId(data?.data()?.receipt);
      }
    };
    // Api call to retrieve the stripe subscription
    const retrieveSub = async () => {
      const result = await axios.post(
        "https://us-central1-selfpause-10e5a.cloudfunctions.net/stripeApi/validateStripeReceipt",
        // "http://localhost:5001/selfpause-10e5a/us-central1/stripeApi/validateStripeReceipt",

        { receiptId }
      );
      // const endDate=result.data.enddate;
      if (result.data.isExpired) {
        setIsExpired(true);
        return;
        // navigate("/payment");
      }
      setIsExpired(false);
      setEndDate(result.data.endDate);
    };

    if (auth.currentUser) {
      getdooc();
    }

    if (receiptId) {
      retrieveSub();
    }
    return () => {
      isMounted.current = false;
    };
  }, [isMounted, navigate, receiptId, auth.currentUser]);

  useEffect(() => {
    setTimeout(() => {
      setCanRender(true);
    }, 500);
  }, []);

  if (!canRender) {
    return (
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress style={{ color: "#008AB7" }} />
      </Box>
    );
  }
  // logout
  const logout = async () => {
    signOut(auth).then(() => {
      window.location.replace("https://selfpause.com/");
    });
  };
  console.log(isExpired, canceled);
  // cancel sub
  const cancelSub = async () => {
    const result = await axios.post(
      // "http://localhost:5001/selfpause-10e5a/us-central1/stripeApi/cancelSub",
      "https://us-central1-selfpause-10e5a.cloudfunctions.net/stripeApi/cancelSub",
      { receiptId }
    );
    if (result.data.deleted) {
      const docref = doc(db, "users", auth.currentUser.uid);

      await updateDoc(docref, { premium: false, receipt: "" });
      setCanceled(true);
      setIsExpired(true);
      setEndDate("");
    }
  };

  return (
    <div className="container">
      <nav>
        <div className="logo">
          <img src={selfPauseLogo} alt="" width="100px" height="100px" />
        </div>
        <div className="links">
          <a className="link" href="https://selfpause.com/contact-us/">
            HELP
          </a>
          <a className="link" href="#" onClick={logout}>
            LOGOUT{" "}
          </a>
        </div>
      </nav>
      {/* web Section */}
      <section className="secondSection">
        <div className="sideBar">
          <div className="sideBarTextDiv">
            <div className="accountLogoDiv">
              {" "}
              <img src={accountLogo} alt="" />
            </div>
            <h2> My Account</h2>
          </div>{" "}
        </div>
        <div className="accountDetails">
          <div className="accountDetailsTextDiv">
            <h1>Account Details</h1>
          </div>
          <div className="susbcriptionSection">
            <table style={{ width: "50%" }}>
              <tbody>
                <tr>
                  <th>Email</th>
                  <th>Name</th>
                  <th>Susbcription</th>
                  <th>Renew Date</th>
                </tr>
              </tbody>
              <tbody>
                <tr>
                  <td>{email}</td>
                  <td>{name}</td>
                  <td>Premium</td>
                  <td>{canceled || isExpired ? "Cancelled" : endDate}</td>
                </tr>
              </tbody>
            </table>
            <div className="subscriptionBtnDiv">
              <button
                className="subscriptionBtn"
                onClick={
                  canceled || isExpired ? () => navigate("/payment") : cancelSub
                }
              >
                {" "}
                {canceled || isExpired ? "Upgrade" : "Cancel"}
              </button>
            </div>
          </div>
        </div>
      </section>
      {canceled && (
        <div className="modal">
          <div className="innerModalDiv">
            <img src={closeLogo} alt="" onClick={() => setCanceled(false)} />
          </div>

          <p>You have successfuly canceled your subscription</p>
        </div>
      )}

      {/* mobile section */}
      <section className="mobileSectionContainer">
        <div className="sideBarMobile">
          <div className="sideBarMobileText">
            <img src="" alt="" />
            <h3>My Account</h3>
          </div>
          {/* <div className="sideBarMobileText">
            <img src="" alt="" />
            <h3>Subscription</h3>
          </div> */}
          {/* <div className="sideBarMobileText">
            <img src="" alt="" />
            <h3>2022 Self-care Themes</h3>
          </div>
          <div className="sideBarMobileText">
            <img src="" alt="" />
            <h3>Premimum Events</h3>
          </div> */}
        </div>
        <div className="accountDetailsMobile">
          <div className="accountDetailsTextDivMobile">
            <h2>Account Details</h2>
          </div>
          <div className="susbcriptionSectionMobile">
            <h3 className="th">Email</h3>
            <h3>{email}</h3>
            <h3 className="th">Name</h3>
            <h3>{name}</h3>
            <h3 className="th">Subscription</h3>
            <h3>Premium</h3>
            <h3 className="th">Renew date</h3>
            <h3>{canceled || isExpired ? "Cancelled" : endDate}</h3>
            <div className="cancelSubBtn">
              <button
                onClick={
                  canceled || isExpired ? () => navigate("/payment") : cancelSub
                }
              >
                {canceled || isExpired
                  ? "Upgrade Subscription"
                  : "Cancel Subscription"}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Account;

import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import "./Payment.css";
import trueIcon from "./true.jpeg";
import emaill from "./email.png";
import { onAuthStateChanged } from "firebase/auth";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { Box, CircularProgress } from "@mui/material";
import Logo from "../../components/logo.png";

const Payment = () => {
  const [Annual, setAnnual] = useState(true);
  const [email, setEmail] = useState(true);
  const [name, setName] = useState(null);
  const [amount, setAmount] = useState(59.99);
  const [trial, setTrial] = useState(7);
  const [processing, setProcessing] = useState(false);
  const [succeded, setSucceded] = useState(false);
  const [error, setError] = useState(false);
  const [productId, setProductId] = useState("prod_MHwKmdLGkqQmgZ"); // PROD
  // const [productId, setProductId] = useState("prod_MGGR3QRb9mAdKy"); // TEST
  const [canRender, setCanRender] = useState(false);
  const [interval, setInterval] = useState("year");
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    if (
      window.location.href === "https://affirmations-generator.selfpause.com/"
    ) {
      navigate("/stepOne");
    }
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setEmail(auth?.currentUser?.email);
          setName(auth?.currentUser?.name);
        } else {
          navigate("/");
        }
      });
    }
    const getdooc = async () => {
      const docreferecene = doc(db, "users", auth?.currentUser?.uid);
      const data = await getDoc(docreferecene);
      if (data) {
        if (data.data().premium === true) {
          window.location.replace("https://app.selfpause.com/account");
        }
      }
    };
    if (auth.currentUser) {
      getdooc();
    }
    if (error) {
      setTimeout(() => {
        setError(false);
        setProcessing(false);
      }, 4000);
    }

    return () => {
      isMounted.current = false;
    };
  }, [isMounted, navigate, error, auth?.currentUser?.uid]);

  const [referral, setReferral] = useState(null);

  useEffect(() => {
    window.rewardful("ready", () => {
      if (window.Rewardful.referral) {
        setReferral(window.Rewardful.referral);
        // setProductId("prod_MLUTVmoRCiTErl"); // PROD
        setProductId("prod_MGGR3QRb9mAdKy"); // TEST
        setAmount(53.99);
      } else {
        setReferral(null);
        setProductId("prod_MHwKmdLGkqQmgZ"); // PROD
        // setProductId("prod_MGGR3QRb9mAdKy") // TEST
        setAmount(59.99);
      }
    });
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setCanRender(true);
    }, 1000);
  }, []);

  if (!canRender) {
    return (
      <Box
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <CircularProgress style={{ color: "#008AB7" }} />
      </Box>
    );
  }
  // handle subscription
  const handleSubmitSub = async (event) => {
    event.preventDefault();
    setProcessing(true);
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
      billing_details: {
        name,
        email,
      },
    });

    if (result.error) {
      console.log(result.error.message);
      setError("Check your card number");
    } else {
      const res = await axios.post(
        "https://us-central1-selfpause-10e5a.cloudfunctions.net/stripeApi/sub",
        // "http://localhost:5001/selfpause-10e5a/us-central1/stripeApi/sub",

        {
          payment_method: result.paymentMethod.id,
          email,
          amount,
          trial,
          productId,
          interval,
          referral,
        }
      );
      if (res.data.error) {
        console.log(res.data);
        setError("Please enter a valid card");
      } else if (res) {
        setProcessing(false);
        setSucceded(true);
        const docRef = doc(db, "users", auth?.currentUser?.uid);
        const updateUser = await updateDoc(docRef, {
          premium: true,
          receipt: res.data.sessionId,
        });
        window.location.replace("https://selfpause.com/upgrade-successful");
      } else {
        setError("Payment failed");
      }
    }
  };

  // const onAnnualPlanClick = () => {
  //   setAnnual(true);
  //   setAmount(59.99);
  //   setTrial(7);
  //   setProductId('prod_MHwKmdLGkqQmgZ');
  //   setInterval('year');
  // };
  // const onMonthlyPlanClick = () => {
  //   setAnnual(false);
  //   setAmount(14.99);
  //   setTrial(0);
  //   setProductId('prod_MHwJOuG6wBoQbF');
  //   setInterval('month');
  // };
  // styling stripe card
  const inputStyle = {
    iconColor: "black",
    color: "inherit",
    fontWeight: "inherit",
    fontFamily: "inherit",
    fontSize: "20px",
    // fontSmoothing: "antialiased",
    ":-webkit-autofill": {
      color: "inherit",
    },
    "::placeholder": {
      color: "#8c8c8c",
    },
  };
  return (
    <div className="container">
      <div className="Logo">
        <img src={Logo} alt="SelfPauseLogo" width="100px" height="100px" />
      </div>
      <div className="form">
        <p style={{ fontFamily: "Comfortaa" }}>Step 2 of 2</p>
        <h2 style={{ fontFamily: "Comfortaa" }}>Enter your billing info</h2>
        {/* first div */}
        <div
          className={!Annual ? "plan containerColor" : "plan"}
          // onClick={onAnnualPlanClick}
        >
          <div className="radio">
            <input
              type="radio"
              name="radio"
              checked={Annual}
              onChange={() => {}}
              // onClick={onAnnualPlanClick}
            />
          </div>
          <div className="planDetails">
            <p className="firstp">Annual</p>
            <p className={Annual ? "secondp" : "secondp planSecondp"}>
              $0 due today
            </p>
            <p>
              Billed annually at{" "}
              <del hidden={referral ? false : true}>$59.99</del>
              <strong>{referral ? " $53.99" : " $59.99"}</strong> after trial.
              Cancel anytime
            </p>
            <div className="inputDiv">
              <input
                type="text"
                value="7-day free trial"
                className={Annual ? "input " : "input planInput"}
                readOnly
              />
            </div>
          </div>
          <div className="hi">
            <input
              type="text"
              className={Annual ? "input" : "input planInput"}
              value="7-day free trial"
              readOnly
            />
          </div>
          <div hidden={referral ? false : true} className={"saveMoney"}>
            Save 10%
          </div>
        </div>

        {/* seconde div */}
        {/* <div
          className={Annual ? 'plan containerColor' : 'plan'}
          style={{ marginBottom: '20px' }}
          onClick={onMonthlyPlanClick}
        >
          <div className='radio'>
            <input
              type='radio'
              name='radio'
              checked={!Annual}
              onChange={() => {}}
              onClick={onMonthlyPlanClick}
            />
          </div>
          <div className='planDetails'>
            <p className='firstp'>Monthly</p>
            <p className='secondp'>$14.99 per month</p>
            <p>cancel anytime</p>
            <div className='inputDiv'>
              <input
                type='text'
                className={
                  !Annual ? 'input smallScreen' : 'input planInput SmallScreen'
                }
                value='Subscribe Today'
                readOnly
              />
            </div>
          </div>
          <div className='hi'>
            <input
              type='text'
              className={!Annual ? 'input' : 'input planInput'}
              value='Subscribe Today'
              readOnly
            />
          </div>
        </div> */}
        {/* benefits */}
        <div className="benefitsContainer">
          <h3>Membership Benefits</h3>
          <div className="benefits">
            <span style={{ color: "#008AB7", fontSize: 25, margin: 10 }}>
              ✔{" "}
            </span>
            <p>
              1,000+ affirmations and guided sessions on health, wealth, sleep,
              and more.
            </p>
          </div>
          <div className="benefits">
            <span style={{ color: "#008AB7", fontSize: 25, margin: 10 }}>
              ✔{" "}
            </span>

            <p>
              Premium library of soothing background sounds, music, and binaural
              beats.
            </p>
          </div>
          <div className="benefits">
            <span style={{ color: "#008AB7", fontSize: 25, margin: 10 }}>
              ✔{" "}
            </span>

            <p>
              Back up your personal library and get your affirmations on any
              device.
            </p>
          </div>
          <div className="benefits">
            <span style={{ color: "#008AB7", fontSize: 25, margin: 10 }}>
              ✔{" "}
            </span>

            <p>
              Write, record and listen to unlimited professional and personal
              affirmations.
            </p>
          </div>
          <div className="benefits email">
            <img src={emaill} width="25px" height="25px" />
            <p>Logged in as {email}</p>
          </div>
          <div className="cardElement">
            <CardElement
              options={{
                style: {
                  base: inputStyle,
                },
              }}
            />
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
          <button
            onClick={handleSubmitSub}
            disabled={processing || succeded}
            style={{ fontFamily: "Comfortaa", backgroundColor: "#008AB7" }}
          >
            {processing ? (
              <>
                <CircularProgress size="1.5rem" style={{ padding: "0px" }} />
                <p
                  style={{
                    margin: "0",
                    marginLeft: "10px",
                    padding: "0px",
                    fontFamily: "Comfortaa",
                  }}
                >
                  Processing
                </p>
              </>
            ) : succeded ? (
              "Success"
            ) : !Annual ? (
              "Submit payment"
            ) : (
              "Start free trial"
            )}
          </button>
          <p>
            {" "}
            By continuing you agree to our{" "}
            <a className="href" href="https://selfpause.com/terms-conditions/">
              Terms of Use{" "}
            </a>{" "}
            and{" "}
            <a className="href" href="https://selfpause.com/privacy-policy/">
              Privacy Policy
            </a>
          </p>
        </div>

        {/* Logged in as .. */}
      </div>
      {/* <div className='bottomParagraphe'>
        <p>
          Your first 7 days are free. $64.99 will be due on Sun Aug 28 2022,
          unless you cancel beforehand. Refund requests accepted within 30 days
          of purchase.
        </p>
        <p>
          {' '}
          Email us at help@theshineapp.com with any questions or if you need
          support.
        </p>
      </div> */}
    </div>
  );
};
export default Payment;

import React from "react";
import { Box, Button, Typography } from "@mui/material";
import Logo from "../../components/logo.png";
import onboarding from "../../components/onboarding.png";
import affirmations from "../../components/Affirmations.png";
import ambient from "../../components/Ambient.png";
import recordings from "../../components/Recordings.png";
import { useNavigate } from "react-router-dom";
import IntroCard from "./IntroCard";

export default function IntroWebView() {
  const navigate = useNavigate();

  return (
    <Box
      mx={"15%"}
      backgroundColor={"#F2FCFC"}
      borderRadius={5}
      flexDirection={"row"}
      display={"flex"}
      mb={10}
    >
      <img
        style={{
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
        }}
        src={onboarding}
        alt="SelfPauseLogo"
        width="50%"
        height="100%"
      />

      <Box mx={4}>
        <Typography sx={{fontFamily:"Comfortaa"}} textAlign={"left"} variant="h6" fontSize={24} my={5} mx={2} fontWeight={700}>
            Unlock your mindset & success with affirmations.
        </Typography>

        <IntroCard
          image={affirmations}
          title={"Guided affirmation sessions by experts"}
          subtitle={
            "Listen to 1,000's of professionally recorded affirmations."
          }
        /> 

        <IntroCard
          image={recordings}
          title={"Self-recorded personal affirmations"}
          subtitle={
            "Write and self-record unlimited personalized affirmations."
          }
        />

        <IntroCard
          image={ambient}
          title={"Premium ambient sounds and music"}
          subtitle={
            "Get the full library of ambient sounds to layer with your affirmations."
          }
        />

        <Box display={"flex"} justifyContent={"center"} my={5}>
          <Button
            style={{
              width: "60%",
              backgroundColor: "#008AB7",
              fontFamily:"Comfortaa"
            }}
            onClick={() => navigate("/authentication")}
            type="submit"
            variant="contained"
          >
            Continue
          </Button>
        </Box>
      </Box>
    </Box>
  );
}


import {
  handshake,
  money,
  shoes,
  love,
  happiness,
  openbook,
  lotus,
  heartbeat,
} from "../../../assets/AffirmationsChoiceAssets/index.js";
export const Categories = [
  {
    firstCategories: [
      { name: "Stress", imgUrl: handshake, selected: false },
      { name: "Money", imgUrl: money, selected: false },
      { name: "Work", imgUrl: openbook, selected: false },
      { name: "Fitness", imgUrl: shoes, selected: false },
    ],
  },
  {
    secondCategories: [
      { name: "Relationships", imgUrl: love, selected: false },
      { name: "Personal Growth", imgUrl: happiness, selected: false },
      { name: "Health", imgUrl: heartbeat, selected: false },
      { name: "Academics", imgUrl: lotus, selected: false },
    ],
  },
];
// sub categories
export let subCategoriesGropu = [
  {
    selected: false,
    name: "Stress",
    array: [
      { selected2: false, subName: "Relaxation" },
      { selected2: false, subName: "Mental Clarity" },
      { selected2: false, subName: "Stress Relief" },
    ],
  },
  {
    selected: false,
    name: "Money",
    array: [
      { selected2: false, subName: "Financial Abundance" },
      { selected2: false, subName: "Debt Management" },
      { selected2: false, subName: "Save Money" },
    ],
  },

  {
    selected: false,
    name: "Work",
    array: [
      { selected2: false, subName: "Zenful Workday" },
      { selected2: false, subName: "Dream Career" },
      { selected2: false, subName: "Enjoy Work" },
    ],
  },

  {
    selected: false,

    name: "Fitness",
    array: [
      { selected2: false, subName: "General Athletic" },
      { selected2: false, subName: "Running" },
      { selected2: false, subName: "Athletic Mindset" },
    ],
  },

  {
    selected: false,
    name: "Relationships",
    array: [
      { selected2: false, subName: "Lasting Relationships" },
      { selected2: false, subName: "Joyful Relationship" },
      { selected2: false, subName: "Commitement" },
    ],
  },
  {
    selected: false,
    name: "Personal Growth",
    array: [
      { selected2: false, subName: "Positive Self-talk" },
      { selected2: false, subName: "Inner Peace" },
      { selected2: false, subName: "Personal Development" },
    ],
  },

  {
    selected: false,
    name: "Health",
    array: [
      { selected2: false, subName: "Best Health" },
      { selected2: false, subName: "More Energy" },
      { selected2: false, subName: "Healing" },
    ],
  },
  {
    selected: false,
    name: "Academics",
    array: [
      { selected2: false, subName: "Study Habits" },
      { selected2: false, subName: "Test Confidence" },
      { selected2: false, subName: "Homework Motivation" },
    ],
  },
];

// affirmations
export const Affirmations = [
  {
    name: "Study Habits",
    array: [
      "I consciously make an effort to implement healthy study habits that help me succeed.",
      "I know I have the skills to accomplish each task because of my time management habits.",
      "I often seek out private and quiet areas to study and complete my coursework.",
      "I know how to eliminate distractions around me that may decrease my ability to focus.",
      "I make doing homework and studying a habit through repetition.",
      "I take note of the homework I need to do each morning to create a mental reminder.",
      "I plan my day according to my task list and set aside time to study.",
      "I prepare for each homework assignment by making sure I bring everything I need.",
      "I make an effort when planning for a study session to set aside anything distracting.",
      "I take time to review, outline, and even rewrite notes that will be important in upcoming exams.",
      "I occasionally study with friends to receive help with understanding new material.",
      "I practice testing myself with memorized content frequently.",
      "I get together with a friend or a group to practice reviewing content to ensure my understanding.",
      "When memorizing content, I get creative and use mnemonic devices to help me remember.",
      "I am confident that I am a successful and capable student with effective study habits.",
    ],
  },
  {
    name: "Test Confidence",
    array: [
      "In preparation for upcoming tests, I thoroughly complete my homework assignments.",
      "I regularly set aside time to review lecture notes and assigned reading materials.",
      "I do a little bit each day to help me solidify the material into knowledge.",
      "I plan my study sessions in advance to relieve me of the need to cram the night before a test.",
      "I enjoy the sense of accomplishment that feeling prepared brings.",
      "I regularly and consistently set aside time to study.",
      "I often study in the same place and at the same time in order to establish a healthy habit.",
      "I know how to use the resources I have in order to achieve my highest academic success.",
      "I am willing to ask my professor for direction when there is something I do not understand.",
      "I build a support system of classmates that I can rely on when I need help.",
      "I begin my test review earlier than my average classmate.",
      "I give myself ample time to review all the material and feel completely prepared.",
      "I listen to my body and put my health as a top priority while preparing for any test.",
      "I drink sufficient water and get a full night's rest the day before an exam.",
      "I make sure to eat nutritious food that will keep me full throughout the duration of a test.",
    ],
  },
  {
    name: "Homework Motivation",
    array: [
      "I know that starting now is the best time to start.",
      "I set aside assignments that I may be struggling with in order to continue making progress.",
      "As long as I continue to try, slowly more and more will get done.",
      "I love to implement unique motivation techniques to reward myself for my dedication.",
      "I am developing a positive mindset toward my homework.",
      "I recognize personal growth comes from problem-solving and doing hard things.",
      "I am cultivating a greater sense of gratitude for my classes.",
      "I love the opportunity my classes give me to learn, grow, and challenge myself.",
      "I make an effort to eliminate all distractions while taking on my homework for the day.",
      "I build a support system of classmates that I can rely on when I need help.",
      "I put my phone notifications on silent.",
      "I move into a separate room from others watching TV or having personal conversations.",
      "I listen to study music to increase focus.",
      "I often find new homework motivation when I study together with a friend or a class group.",
      "I understand that my overall wellness may have an impact on my academic performance.",
    ],
  },
  {
    name: "General Athletics",
    array: [
      "I am an amazing athlete.",
      "I am a strong competitor.",
      "I am faster than the wind.",
      "I am a winner at whatever I compete in.",
      "I have amazing stamina.",
      "I am committed to my training.",
      "I am dedicated and excited to practice.",
      "I am a highly-skilled athlete.",
      "I am always focused on winning.",
      "I practice every day.",
      "I am turning into the greatest athlete.",
      "I am fast.",
      "The skillsets for my sport are deeply ingrained in me to the point of perfection.",
      "Through practice, I am developing my full athletic capabilities.",
      "I always surpass the athletic expectations others have.",
    ],
  },
  {
    name: "Running",
    array: [
      "I am dedicated to running.",
      "I was born to run and I love doing it.",
      "I enjoy my training routine.",
      "I wake up excited to run.",
      "I feel amazing during and after a running session.",
      "I continually push myself to go faster and further.",
      "I am quick and agile.",
      "I have a strong capability to endure.",
      "I am always excited to go running.",
      "Running and training are a sense of joy in my life.",
      "I am turning into the greatest athlete.",
      "I thrive off of consistency as I stick to my training schedule.",
      "Every time I complete a session, I increase my stamina and performance.",
      "I am always motivated to become stronger and faster.",
      "Each day it is easier for me to run farther and faster.",
    ],
  },
  {
    name: "Athletic Mindset",
    array: [
      "I am an incredible athlete.",
      "I choose to stay motivated to train.",
      "I am positive while I train.",
      "I am seeking opportunities to push myself and become better.",
      "I am willing to do what it takes to be successful.",
      "I receive a sense of accomplishment when I practice hard.",
      "I am able to stay calm under pressure.",
      "I constantly push myself to become the best I can become.",
      "I have a desire and ability to improve.",
      "I choose to work hard.",
      "I am a determined and focused athlete.",
      "I thrive off of competition.",
      "I am cultivating my skills to become an excellent athlete.",
      "I never give up, especially when a challenge is present.",
      "I choose to find opportunities to improve.",
    ],
  },
  {
    name: "More Energy",
    array: [
      "I am a high-energy individual and am always motivated to get things done.",
      "I am happy and excited to get out of bed every morning.",
      "I find joy in getting my day started and this excitement transfers into every part of my day.",
      "I am a naturally happy and energetic individual.",
      "I feel that living my life is exhilarating.",
      "I enjoy using my energy to achieve my goals.",
      "I am ready to conquer the things I need to do today.",
      "I am positive about all of the factors of my day.",
      "I become more energetic every day.",
      "I easily find the motivation to do hard things in my day.",
      "I find myself more refreshed each morning and ready to get things done.",
      "I am a natural go-getter and an optimistic achiever.",
      "I am one who doesn’t give up until they finish.",
      "I find it easy to fall asleep at night knowing I finished what I needed to do that day.",
      "I choose to be positive throughout my whole day.",
    ],
  },
  {
    name: "Healing",
    array: [
      "I feel amazing and find joy in all aspects of my life.",
      "I find it easy to relax and focus on gratitude each day.",
      "I am a calm and peaceful individual.",
      "When I need to, I am able to release all tension in my body and relax.",
      "I am able to find peace in deep breathing exercises.",
      "I am the master of my fate and I choose to be positive and happy.",
      "I am relaxed and ready to take on anything in my life.",
      "My body is happy, healthy, and filled with light and joy.",
      "I focus only on the good that the future brings to me.",
      "I am calm and peaceful despite my environment because I am in full control of my attitude.",
      "I am quickly becoming more relaxed and positive by the day.",
      "I am someone who is completely positive in mind and spirit.",
      "When I look to the future with peace and hope, I am full of joy and comfort.",
      "Each day I learn to love myself more.",
      "I have the power to allow healing to happen anywhere and in any way, it needs to in my life.",
    ],
  },
  {
    name: "Best Health",
    array: [
      "I am healthy in every aspect of my life.",
      "My mind is positive and healthy.",
      "My body is strong.",
      "I seek to live a healthy lifestyle in everything I do.",
      "I actively strive for longevity.",
      "I only eat foods that will benefit my health and give my body raw energy.",
      "I believe that I am in great health and my body can do powerful things.",
      "I look forward to when I wake up.",
      "I know that my day has incredible potential.",
      "I have resilient lungs that provide my body with fresh oxygen.",
      "I possess a strong, healthy heart that constantly circulates the blood my body needs.",
      "I love exercising every day and seeing how this habit improves my life.",
      "I believe that my consistent exercise can change my world.",
      "Everyone around me supports me and my lifestyle and wants to help me succeed.",
      "I am happy to be an example of health and happiness to others.",
    ],
  },
  {
    name: "Positive Self-talk",
    array: [
      "I am an incredible person that does many important things every day.",
      "People love me for who I am and for who I will become- not simply for what I’ve done.",
      "My future is bright and full of hope.",
      "My past does not define me.",
      "I look for situations that trigger negative self-talk and stop any negative thought processes.",
      "I am continually improving in every aspect of my life as long as I continue trying.",
      "I choose to surround myself with positive people that love themselves and love me.",
      "I am someone who helps lift others up and find the good in themselves.",
      "I am more powerful than my emotions.",
      "I have many people cheering me on.",
      "Everyone notices the good things I do and my best moments never go unrecognized.",
      "I am invincible in the face of any challenge.",
      "I am in control of my actions despite my emotions.",
      "I have people who love me and are hoping I succeed.",
      "I am extremely independent and stronger than I have ever realized.",
    ],
  },

  {
    name: "Inner Peace",
    array: [
      "I let go of all of the emotions inside of me.",
      "I know what I am capable of.",
      "I forget the chaos around me and allow my mind to become completely at ease.",
      "I choose to redirect this moment to something that gives me joy.",
      "I am content with who I am, what I’ve done, and who I am becoming.",
      "I understand that personal development is a growing process that takes time",
      "I feel a source of calm joy in my heart as I take a few moments to breathe and relax.",
      "I am truly at peace with myself and my life.",
      "No past mistakes or negative emotions can take my confidence away from me.",
      "I am confident that, even with my challenges and my failures, I will be my best self.",
      "I take a moment to reflect on what my best self would be like.",
      "I am happy within myself at this moment.",
      "I imagine my future filled with hope and success.",
      "I am not a quitter.",
      "I am able to simply let go of my past mistakes.",
    ],
  },

  {
    name: "Personal Development",
    array: [
      "I am becoming more and more aware of how important I am in my field of influence.",
      "People look up to me, they need me, and I serve a very important role.",
      "I am loved by others.",
      "I often reflect on the people who love me, who give me support, and who want to see me happy.",
      "I am aware of the things I do that are limiting my happiness.",
      "I recognize the things that cause pain that are in my power to eliminate and begin doing so.",
      "I take time each day to contemplate the things I am not doing but should start doing.",
      "I ponder the things I am doing but should stop doing.",
      "I am strong enough to do anything I want to do.",
      "I am happy to look for simple ways I can improve.",
      "I love to do little things each day to try and become better.",
      "I am happy within myself at this moment.",
      "The benefits of small positive changes create a recognizable difference in my life.",
      "I am now willing to stop procrastinating on the things I have been putting off.",
      "I seek out good things that add value to my life.",
    ],
  },
  {
    name: "Joyful Relationship",
    array: [
      "I am choosing to develop a peaceful and fulfilling relationship.",
      "I am dedicated to the success of my relationship.",
      "I am joyfully invested in the education, talents, and positive experiences of my significant other.",
      "I truly want to see them succeed in all aspects of their life.",
      "I am a happy and confident individual on my own, but my relationship still strengthens me.",
      "My relationship has increased my confidence and happiness.",
      "I deeply respect and admire my partner.",
      "Making my partner happy is sincerely an important priority in my life.",
      "I choose to be positive and loving with my partner.",
      "I communicate with honesty and love.",
      "I am so grateful for the joy my relationship brings me.",
      "I choose to do everything I know how to do to develop my relationship.",
      "We are gradually learning how to work and grow together.",
      "I cultivate a feeling of selflessness in my relationship and I look for reasons to be happy.",
      "The love my partner and I share is continually increasing.",
    ],
  },
  {
    name: "Lasting Relationships",
    array: [
      "I am eager to forgive my significant other when they wrong me.",
      "My relationship flows better when my significant other and I are quick to forgive and forget.",
      "I am a positive and loving person.",
      "I plan to always love and support my partner unconditionally.",
      "I always communicate with my partner with respect and understanding.",
      "I am willing to listen to their perspective with patience and kindness.",
      "I listen to my partner and am willing to make personal changes for them. I do this because I am investing in a lasting relationship.",
      "My partner’s trust in me is increasing every time I include them in my decisions.",
      "I am entirely honest and open with my significant other about my emotions.",
      "I allow myself to have personal time to think about and take care of myself.",
      "I am mindful of my own needs and am willing to take as much me-time as I need to relax.",
      "I intentionally cultivate a lasting relationship with my partner.",
      "My relationship is becoming stronger and more resilient every day.",
      "I truly love and appreciate my significant other.",
      "Every day I seek to develop virtuous qualities to bring to my relationship.",
    ],
  },
  {
    name: "Commitement",
    array: [
      "I am happily committed to my significant other.",
      "I demonstrate my commitment by seeking to improve our relationship.",
      "I am happy and secure in this relationship.",
      "I believe this relationship is capable of being successful and long-lasting.",
      "I am increasing my commitment to my partner every day.",
      "Each day I am reminded of how much I cherish our relationship.",
      "I put effort into making sure my significant other feels secure.",
      "I am confident in the love that my significant other and I share.",
      "I am extremely committed to preserving our love.",
      "I allow myself to have personal time to think about and take care of myself.",
      "I am continually developing my committed relationship.",
      "I am confident that I am committed to the right person.",
      "Every day my commitment grows stronger and deeper.",
      "I believe that I am a happy and committed person.",
      "When I show commitment to my significant other, the commitment is reciprocated.",
    ],
  },
  {
    name: "Stress Relief",
    array: [
      "I release all tension from my mind and relax each muscle of my body.",
      "I take time to find gratitude for what I have, this relieves my stress.",
      "I will deal with life as it comes; I don’t have to worry about it all right now.",
      "I choose to focus on the peace that my life offers.",
      "I am able to remain calm even when doing challenging tasks.",
      "I am able to find peace at any point of my day.",
      "Peace is a choice; I can choose how to allow it into my life.",
      "My life is stress-free.",
      "I choose to focus on my blessings instead of my to-do's.",
      "I allow my whole body to relax and to take deep breaths.",
      "I am choosing to let go of concerns.",
      "My mind is calm, and my body is at peace.",
      "I have confidence that good things will come.",
      "I breathe and fill my lungs with goodness, I exhale to push out the bad.",
      "I fill myself with peace.",
    ],
  },
  {
    name: "Mental Clarity",
    array: [
      "I can easily organize my thoughts.",
      "I am able to effortlessly recall anything I would like to remember.",
      "I have a strong sense of peace and mental clarity in my mind.",
      "I can focus on individual tasks without feeling the weight of my whole to-do list.",
      "I am relaxed enough to deal with anything.",
      "I have a balanced amount of work and play.",
      "I am confident I can relax and respond well to challenging situations.",
      "My internal peace is something I know I can successfully control.",
      "My mind is translucent, and my thoughts are intelligible.",
      "My mind is always sharp and clear.",
      "I take time to think about things when I need to.",
      "I know when I need to step back and relax.",
      "I am able to have a clear and calm mind when I need it.",
      "I know when and how to invite more peace into my mind.",
      "I am awake, coherent, and able to utilize my mind as I go about my day.",
    ],
  },
  {
    name: "Relaxation",
    array: [
      "I am calm. I am tranquil. I am able to breathe in and out.",
      "My body is calm, my mind is at peace, and I am entirely at rest.",
      "I take one deep breath in… and slowly release it.",
      "I allow all tension in my body to be released, and my thoughts become clear.",
      "I am choosing to release all of my fears, my worries, and my stresses.",
      "I focus on the air I have to breathe and on my gratitude for my abundant life.",
      "Stress is powerless in the presence of peace, it simply melts away.",
      "I choose peace within myself and I am left feeling happy, calm, and powerful.",
      "My muscles are still, and my mind is tranquil.",
      "I choose to let go of my concerns and recenter my mind on what needs my focus.",
      "I am becoming entirely balanced and calm.",
      "Each day I invite more and more peace into my life.",
      "I intuitively take deep breaths and release my negative emotions.",
      "I can choose to relax easily and effortlessly.",
      "I am entirely focused and at peace.",
    ],
  },
  {
    name: "Save Money",
    array: [
      "I am great at saving money and am wise with the income I have.",
      "I am committed to saving money.",
      "I am always growing my savings and investments.",
      "I am always finding more ways to save money.",
      "I am in complete control of my financial life.",
      "I watch my spending habits closely.",
      "Stress is powerless in the presence of peace, it simply melts away.",
      "I am always planning my financial expenses and decisions.",
      "I always plan my spending so as to have surplus money to invest and save for later.",
      "I am great at consistently saving money.",
      "I am extremely disciplined with my finances.",
      "I never spend money impulsively.",
      "I always find ways to save my money.",
      "My bank account is growing quickly as I always find new ways to increase my savings.",
      "I am turning into someone who has a strong sense of finance.",
    ],
  },
  {
    name: "Debt Management",
    array: [
      "I am entirely capable of managing my debt.",
      "My debt does not define me.",
      "I am able to spend my money wisely.",
      "I am committed to a debt-free life.",
      "I am committed to eliminating all debt from my life.",
      "I am thoughtful in my spending decisions.",
      "I am disciplined and only spend what I have to.",
      "I am happily living an abundant yet frugal lifestyle.",
      "I am excited and committed to being completely debt-free.",
      "I am mindful of and committed to reducing my debt.",
      "I am a financially independent person.",
      "I am becoming more financially stable each and every day.",
      "I live a simple, frugal lifestyle and love it.",
      "I have the power to overcome my debt and live entirely financially free.",
      "I only say yes to the things I absolutely need.",
    ],
  },
  {
    name: "Financial Abundance",
    array: [
      "I trust the money that I need to always come to me.",
      "I am generous with my wealth.",
      "I have unlimited resources in my life.",
      "I seek to use my money to have a positive impact on the world.",
      "The world is ready and willing to send money my way, all I have to do is receive it.",
      "I can afford to live anywhere I want to live.",
      "I have the financial freedom to choose whatever job I would like to do.",
      "I have the ability right now to live the lifestyle I have always dreamed of.",
      "I have my dream house with my dream car.",
      "I am in control of my own financial abundance.",
      "I seek to uplift others with my financial freedom.",
      "I have the time and freedom to spend time doing things I love.",
      "I am willing and able to financially take care of anyone I love.",
      "I can afford to travel and see the world.",
      "I am so proud of my success.",
    ],
  },
  {
    name: "Zenful Workday",
    array: [
      "I am at peace in mind and body.",
      "Today is a beautiful day to go to work, get lots done, and feel accomplished.",
      "I choose to be relaxed by simply controlling the way I breathe.",
      "I consciously override any unwanted emotions by manifesting peacefulness.",
      "I complete each task effortlessly.",
      "I am finding my workday to be comfortable.",
      "The more peace I affirm in my day, the more my work flies by.",
      "I am in complete control of my mind, body, and spirit.",
      "Every problem that presents itself comes with an accompanying solution.",
      "I find my work relaxing..",
      "I find my workday fulfilling.",
      "I allow small details of my work environment to bring me joy.",
      "I am someone that others can depend upon to be calm and collected.",
      "I am a positive influence on those who work with me.",
      "I am a light everywhere I go.",
    ],
  },
  {
    name: "Dream Career",
    array: [
      "I am open to change and am interested in new opportunities.",
      "I deserve to do a job that excites me.",
      "I am capable of attracting the best career.",
      "I keep my eyes open for interesting opportunities and always stay optimistic.",
      "I am able to network easily with others.",
      "My network is increasing because people respond well to my professional charm.",
      "I have all of the qualifications for my dream job.",
      "As I embark on this journey, I find patience each day as well as confidence.",
      "I am becoming more positive and confident every day.",
      "I continually seek opportunities that will help me achieve my professional goals.",
      "I strive every day to attract my future dream job.",
      "I will gain the skills required to get my dream job.",
      "I am qualified to work the job I want.",
      "I am committed to achieving my career goals.",
      "Every day, I move closer and closer to getting my dream job.",
    ],
  },
  {
    name: "Enjoy Work",
    array: [
      "I feel at ease and get along well with my co-workers.",
      "I enjoy their company and they enjoy mine.",
      "I am my happiest self when I am working hard.",
      "The workday flies by for me when I am busy at work.",
      "I look forward to my time at work because of the great people and environment.",
      "I find it easy to have a positive attitude towards work.",
      "People view me as naturally motivated.",
      "I am always smiling at work.",
      "I am skilled and needed at my job because of my wealth of knowledge.",
      "Because I work hard and feel valuable at my job, I enjoy my time at work.",
      "I am growing more connected to my peers at work.",
      "I am turning into someone who is always cheerful.",
      "I find myself feeling happier and more fulfilled while at work.",
      "I am transforming into someone who genuinely enjoys my job.",
      "I am a diligent and productive worker who gets a lot done.",
    ],
  },
];

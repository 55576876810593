import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  updateProfile,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { setDoc, getDoc, doc, serverTimestamp } from "firebase/firestore";
import toast from "react-hot-toast";
import "./Auth.css";
import googleIcon from "./googleIcon.svg";
import Apple from "./apple.jpg";
import Logo from "../../components/logo.png";

const Auth = () => {
  const [signup, setSignup] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.location.href === "https://affirmations-generator.selfpause.com/"
    ) {
      navigate("/stepOne");
    }
    window.scrollTo(0, 0);
  }, []);

  // register user
  const registerUser = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      updateProfile(auth.currentUser, {
        displayName: firstName,
      });

      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      // if user does'nt exist then create user
      if (!docSnap.exists()) {
        await setDoc(docRef, {
          fullName: user.displayName,
          email: user.email,
          createdAt: serverTimestamp(),
          premium: false,
          uid: user.uid,
          myMix: [],
        });
      }
      navigate("/payment");
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong with registration");
    }
  };
  // Register or signin with google
  const onGoogleClick = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // check for user
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      // if user does'nt exist then create user
      if (!docSnap.exists()) {
        await setDoc(docRef, {
          fullName: user.displayName,
          email: user.email,
          createdAt: serverTimestamp(),
          premium: false,
          uid: user.uid,
          myMix: [],
        });
      }
      navigate("/payment");
    } catch (error) {
      console.log(error);
      toast.error("could not authorize with Google");
    }
  };
  // Sign in with email and password

  const signIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential.user) {
        navigate("/payment");
      }
    } catch (error) {
      toast.error("Wrong Credentials");
    }
  };
  // Sign in with Apple
  const signInWithApple = async () => {
    try {
      const provider = new OAuthProvider("apple.com");

      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const docRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(docRef);
      // if user does'nt exist then create user
      if (!docSnap.exists()) {
        await setDoc(docRef, {
          fullName: user.displayName,
          email: user.email,
          createdAt: serverTimestamp(),
          premium: false,
          uid: user.uid,
          myMix: [],
        });
      }
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;
      navigate("/payment");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <div className="Logo">
        <img src={Logo} alt="SelfPauseLogo" width="60px" height="60px" />
      </div>
      <div className="form">
        <p style={{ fontFamily: "Comfortaa" }}>Step 1 of 2</p>
        <h2 style={{ fontFamily: "Comfortaa" }}>
          {signup ? "Create an account" : "Log in to your account"}
        </h2>
        {signup ? (
          <input
            type="text"
            placeholder="First Name*"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            style={{ fontFamily: "Comfortaa" }}
            value={firstName}
          />
        ) : (
          ""
        )}
        <input
          type="Email"
          placeholder="Email*"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          style={{ fontFamily: "Comfortaa" }}
          value={email}
        />
        {/* <div className='password'> */}
        <input
          type="Password"
          placeholder="Password*"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          style={{ fontFamily: "Comfortaa" }}
          value={password}
        ></input>
        {/* </div> */}

        <button
          onClick={signup ? registerUser : signIn}
          className="CreateAccount"
        >
          <p>{signup ? " Create an account" : "Login"}</p>
        </button>
        <button onClick={onGoogleClick} className="Google">
          <img
            src={googleIcon}
            alt="googleicon"
            width="25px"
            height="25px"
            style={{ marginRight: "40px" }}
          />
          <p>{signup ? "Sign up" : "Sign in"} with Google</p>
        </button>

        <button onClick={signInWithApple} className="Apple">
          <img
            src={Apple}
            alt="Apple"
            width="25px"
            height="25px"
            style={{
              marginRight: "30px",
            }}
          />
          <p>{signup ? "Sign up" : "Sign in"} with Apple</p>
        </button>

        <p style={{ fontFamily: "Comfortaa" }}>
          {" "}
          By continuing you agree to our{" "}
          <a
            href="https://selfpause.com/terms-conditions/
"
          >
            Terms of Use{" "}
          </a>{" "}
          and <a href="https://selfpause.com/privacy-policy/">Privacy Policy</a>
        </p>
        {signup ? (
          <p style={{ fontFamily: "Comfortaa" }}>
            Already have a Selfpause account?
            <span
              className="loginSpan"
              onClick={() => {
                setSignup(!signup);
              }}
            >
              {" "}
              Login{" "}
            </span>
          </p>
        ) : (
          <p>
            Don't have a Selfpause account?
            <span
              className="loginSpan"
              onClick={() => {
                setSignup(!signup);
              }}
            >
              Create one
            </span>{" "}
          </p>
        )}
      </div>
    </div>
  );
};

export default Auth;
